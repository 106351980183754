import { CLIENT_ENV, SERVER_ENV } from '@/config/configuration';
import { isTrue } from '@/utils/string';
import { StoryblokClient, apiPlugin, storyblokInit } from '@storyblok/js';
import { StoryblokStory } from 'storyblok-generate-ts';
import { StoryblokComponent } from '@/types/types-storyblok';
import { unstable_cache } from 'next/cache';

let storyblokApi: StoryblokClient | undefined;
type Story = { name: string; slug: string };
export const initStoryblok = () => {
  if (storyblokApi) return;
  const storyblokToken = SERVER_ENV().STORYBLOK_TOKEN_DRAFT ? SERVER_ENV().STORYBLOK_TOKEN_DRAFT : SERVER_ENV().STORYBLOK_TOKEN;
  const { storyblokApi: api } = storyblokInit({
    accessToken: storyblokToken,
    bridge: true,
    apiOptions: {},
    use: [apiPlugin],
  });
  storyblokApi = api;
};

let storyblokRequests = 0;

const fetchCmsStory = async <T extends StoryblokComponent = StoryblokComponent>(slug: string, draftMode: boolean = false) => {
  if (!storyblokApi || isTrue(SERVER_ENV().SKIP_BUILD_STATIC_GENERATION)) return;
  try {
    storyblokRequests++;
    console.log(`Storyblok -> ${slug} / ${storyblokRequests}`);

    const { data } = await storyblokApi.get(`cdn/stories/${slug}`, {
      version: draftMode ? 'draft' : CLIENT_ENV().NEXT_PUBLIC_STORYBLOK_VERSION,
      token: draftMode ? SERVER_ENV().STORYBLOK_TOKEN_DRAFT : undefined,
      resolve_relations: ['author'],
      resolve_links: 'url',
    });
    return data?.story as StoryblokStory<T>;
  } catch (e) {
    console.error(e);
    throw new Error('getCmsStory');
  }
};

export const getCmsStoryPagesRouter = (slug: string, draftMode: boolean = false) => {
  console.log(`Uncached Storyblok request ${slug}`);
  return fetchCmsStory(slug, draftMode);
};

export const getCmsStory = unstable_cache(fetchCmsStory, ['cms']);

const removeUndefinedProperties = (obj: Record<string, any>) => {
  return Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== undefined));
};

export const getAllPrenotaVisitaPages = async (draftMode: boolean = false) => {
  if (!storyblokApi || isTrue(SERVER_ENV().SKIP_BUILD_STATIC_GENERATION)) return;
  const storyblokPages = await storyblokApi.getAll(`cdn/stories`, {
    version: draftMode ? 'draft' : CLIENT_ENV().NEXT_PUBLIC_STORYBLOK_VERSION,
    // @ts-ignore --- storyblok types are not correct
    is_startpage: 0,
    by_slugs: 'prenota-visita/*',
  });

  const servicePages = storyblokPages
    .filter(service => !service.slug.includes('_'))
    .map((service: Story) => ({ name: service.name, slug: service.slug }));

  const serviceLocationPages = storyblokPages
    .filter(service => service.slug.includes('_'))
    .map((service: Story) => ({ name: service.name, slug: service.slug }));

  return {
    servicePages,
    serviceLocationPages,
  };
};

export const getAllCmsStory = async <T extends StoryblokComponent = StoryblokComponent>({
  excluding_fields,
  resolve_relations,
  starts_with,
  is_startpage,
  per_page,
  page,
  sort_by,
  filter_query,
  search_term,
}: {
  starts_with?: string;
  excluding_fields?: string;
  resolve_relations?: string[];
  is_startpage?: boolean;
  per_page?: number;
  // it will fetch only the specified page if it's defined
  page?: number;
  sort_by?: string;
  filter_query?: Record<string, any>;
  search_term?: string;
} = {}) => {
  if (!storyblokApi || isTrue(SERVER_ENV().SKIP_BUILD_STATIC_GENERATION)) return;
  const res = await storyblokApi.get(
    `cdn/stories`,
    removeUndefinedProperties({
      // eslint-disable-next-line no-process-env
      version: process.env.NEXT_PUBLIC_STORYBLOK_VERSION === 'published' ? 'published' : 'draft',
      // eslint-disable-next-line no-process-env
      token: process.env.STORYBLOK_TOKEN_DRAFT,
      resolve_relations,
      starts_with,
      per_page,
      page,
      is_startpage,
      excluding_fields,
      sort_by,
      filter_query,
      search_term,
    }),
  );
  return res as {
    data: {
      stories: StoryblokStory<T>[];
    };
    total: number;
  };
};
