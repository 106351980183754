import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/prenota-visita/_storyblok/smallCardsSection.tsx\",\"import\":\"Lora\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"500\",\"style\":\"italic\"}],\"variableName\":\"lora\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/prenota-visita/_storyblok/howWorkText.tsx\",\"import\":\"Lora\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"500\",\"style\":\"italic\"}],\"variableName\":\"lora\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/prenota-visita/_storyblok/howWorkCards.tsx\",\"import\":\"Lora\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"500\",\"style\":\"italic\"}],\"variableName\":\"lora\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/prenota-visita/_storyblok/bookingCardListing.tsx\",\"import\":\"Lora\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"500\",\"style\":\"italic\"}],\"variableName\":\"lora\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/prenota-visita/_storyblok/faq/FaqList.tsx\",\"import\":\"Lora\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"500\",\"style\":\"italic\"}],\"variableName\":\"lora\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/_components/collapsible.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/_components/layouts/footer/footerWrapper/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/_components/layouts/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/_components/layouts/headerMenu/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/_components/searchbar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/_components/widgets/listing/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/prenota-visita/_storyblok/faq/FaqCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StoryblokHomeHeroBanner"] */ "/app/src/app/prenota-visita/_storyblok/homeHeroBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StoryblokHomeHeroBannerSearchForm"] */ "/app/src/app/prenota-visita/_storyblok/searchForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VisitMainInformation"] */ "/app/src/app/prenota-visita/_storyblok/visitMainInformation.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/assets/medical-services-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/service-card-chevron-right-icon.svg");
