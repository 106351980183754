import { render, StoryblokRichtext } from 'storyblok-rich-text-react-renderer';
import { StoryblokReactComponent } from '@/app/prenota-visita/_storyblok/componentsMap';
import { FaqListStoryblok } from '@/types/types-storyblok';
import { replaceAdditionalInfoInRichText } from '@/app/prenota-visita/_storyblok/utils';
import { StoryblokFaqCard } from '@/app/prenota-visita/_storyblok/faq/FaqCard';
import { generateFaqListJsonLd } from '@/app/_jsonLd/jsonLdFaq';
import { Lora } from 'next/font/google';

const lora = Lora({ subsets: ['latin'], weight: '500', style: 'italic' });

const defaultTitle: StoryblokRichtext = {
  type: 'doc',
  content: [
    {
      type: 'heading',
      attrs: {
        level: 2,
      },
      content: [
        {
          text: 'Hai ancora qualche ',
          type: 'text',
        },
        {
          text: 'dubbio?',
          type: 'text',
          marks: [
            {
              type: 'bold',
            },
          ],
        },
      ],
    },
  ],
};

export const StoryblokFaqList: StoryblokReactComponent<FaqListStoryblok> = ({ component, story, additionalData }) => {
  return (
    <section className="flex flex-col items-center justify-center gap-7 bg-brandBlue-800 px-4 py-14 md:gap-12 md:px-0 md:py-24">
      <script
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(generateFaqListJsonLd(component.list)),
        }}
        type="application/ld+json"
      />

      <div className="flex flex-col gap-7 text-center text-white md:gap-8">
        {render(component.title?.content[0]?.text ? component.title : defaultTitle, {
          markResolvers: {
            bold: child => <span className={`font-semibold italic ${lora.className}`}>{child}</span>,
          },
          nodeResolvers: {
            heading: (children, { level }) => {
              const HeadingTag = `h${level}` as keyof JSX.IntrinsicElements;
              return <HeadingTag className={'text-2xl md:text-5xl'}>{children}</HeadingTag>;
            },
          },

          textResolver: text =>
            replaceAdditionalInfoInRichText(text, { location: additionalData.location, service: additionalData.service }),
        })}
        <p className="text-base md:text-2xl">{component.subtitle || 'Consulta le nostre risposte alle domande più frequenti'}</p>
      </div>
      <div className="flex w-full max-w-[600px] flex-col gap-7 md:gap-9">
        {component.list.map((faq, index) => (
          <StoryblokFaqCard key={index} component={faq} story={story} additionalData={additionalData} />
        ))}
      </div>
    </section>
  );
};
