import {
  AggregatorLicensedSlugsDocument,
  AggregatorWorkgroup,
  GetUnlicensedAggregatorDocument,
  WorkgroupBySlugDocument,
} from '@/types-aggregatore';
import client from '@/utils/apolloClient';
import { sendErrorToSlack } from '@/utils/slack';

export const convertStringToSlug = (value?: string) => (value ? value.replace(/ /g, '-') : '');

export const defaultServicesForPrenotaVisitaLocation = [
  {
    slug: 'carbossiterapia',
    title: 'Carbossiterapia',
  },
  {
    slug: 'visita-dermatologica',
    title: 'Visita dermatologica',
  },
  {
    slug: 'ecografia-muscolo-tendinea',
    title: 'Ecografia muscolo tendinea',
  },
  {
    slug: 'ecografia-addome-completo',
    title: 'Ecografia addome completo',
  },
  {
    slug: 'tecarterapia',
    title: 'Tecarterapia',
  },
  {
    slug: 'elettrocardiogramma-ecg',
    title: 'Elettrocardiogramma',
  },
  {
    slug: 'radiografia-torace-rx',
    title: 'Radiografia Torace',
  },
  {
    slug: 'radiografia-anca-rx',
    title: 'Radiografia Anca',
  },
  {
    slug: 'risonanza-magnetica-ginocchio',
    title: 'Risonanza magnetica ginocchio',
  },
];

export const defaultServicesForPrenotaVisita = [
  {
    slug: 'ecografia-addome-completo',
    title: 'Ecografia Addome Completo',
  },
  {
    slug: 'radiografia-torace-rx',
    title: 'Radiografia Torace (RX)',
  },
  {
    slug: 'visita-dermatologica',
    title: 'Visita Dermatologica',
  },
  {
    slug: 'visita-ginecologica',
    title: 'Visita Ginecologica',
  },
  {
    slug: 'visita-cardiologica',
    title: 'Visita Cardiologica',
  },
  {
    slug: 'visita-oculistica',
    title: 'Visita Oculistica',
  },
  {
    slug: 'visita-neurologica',
    title: 'Visita Neurologica',
  },
  {
    slug: 'visita-odontoiatrica',
    title: 'Visita Odontoiatrica',
  },
  {
    slug: 'visita-urologica',
    title: 'Visita Urologica',
  },
];

export const convertSlugToString = (value?: string) => (value ? value.replace(/-/g, ' ') : '');
import { unstable_cache } from 'next/cache';

export const getWorkgroupBySlug = unstable_cache(
  async (slug: string) => {
    console.log(`Obtaining workgroup with slug ${slug}`);
    const wk = await client.query({
      query: WorkgroupBySlugDocument,
      variables: { slug },
      fetchPolicy: 'no-cache',
    });
    return wk;
  },
  ['getWorkgroupBySlug'],
);

export const staticLicensedAmbulatories = async (): Promise<AggregatorWorkgroup[]> => {
  try {
    const slugs = await getAllLicensedAggregatorSlugs();
    console.log(`Found ${slugs.length} workgroup IDs`);
    const workgroups: AggregatorWorkgroup[] = [];

    for (const slug of slugs) {
      try {
        const wk = await getWorkgroupBySlug(slug);
        const isWebPageVisibleToPatients = wk.data.workgroupBySlug.showOnPrivate;

        if (isWebPageVisibleToPatients) workgroups.push(wk.data.workgroupBySlug as AggregatorWorkgroup);

        if (workgroups.length % 10 === 0) console.log(`Fetched ${workgroups.length} of ${slugs.length} workgroups`);
      } catch (error) {
        console.error('Error fetching workgroup by id ============>', slug, error);
        await sendErrorToSlack(`Error fetching workgroup by slug ==> ${slug} ==> ${(error as unknown as Error)?.message}`);
      }
    }

    return workgroups;
  } catch (error) {
    throw new Error((error as unknown as Error)?.message);
  }
};

export const staticUnlicensedAmbulatories = async (): Promise<AggregatorWorkgroup[]> => {
  try {
    const result = await client.query({
      query: GetUnlicensedAggregatorDocument,
    });

    return result.data.unlicensedWorkgroups ?? [];
  } catch (error) {
    throw new Error((error as unknown as Error)?.message);
  }
};

// FIXME: to fix in story sc-52860
const getAllLicensedAggregatorSlugs = async (): Promise<string[]> => {
  //TODO: to be refactored https://app.shortcut.com/davincisalute/story/52860/aggregator-refactor-licensedworkgroupsids
  return (await getLicensedAggregatorSlugs(100000, 0))?.data?.licensedWorkgroupSlugs?.results ?? [];
};

const getLicensedAggregatorSlugs = async (limit: number, skip: number) => {
  const variables = { limit: limit, offset: skip };
  return await client.query({ query: AggregatorLicensedSlugsDocument, variables });
};
