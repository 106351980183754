import { MedicalVisitCardStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from './componentsMap';
import Link from 'next/link';
import { capitalizeWordsInString } from '@/utils/string';

const RightArrowIcon = () => (
  <svg width="18" height="8" viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9331 0.433058C13.1771 0.188981 13.5729 0.188981 13.8169 0.433058L16.9419 3.55806C17.186 3.80214 17.186 4.19786 16.9419 4.44194L13.8169 7.56694C13.5729 7.81102 13.1771 7.81102 12.9331 7.56694C12.689 7.32286 12.689 6.92714 12.9331 6.68306L14.9911 4.625H1.5C1.15482 4.625 0.875 4.34518 0.875 4C0.875 3.65482 1.15482 3.375 1.5 3.375H14.9911L12.9331 1.31694C12.689 1.07286 12.689 0.677136 12.9331 0.433058Z"
      fill="#0F285E"
    />
  </svg>
);

const MediacalIcon = () => (
  <span className="flex size-6 shrink-0 items-center justify-center rounded-full bg-zaffiro-400">
    <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_971_11572)">
        <path
          d="M20 6H16V4C16 2.9 15.1 2 14 2H10C8.9 2 8 2.9 8 4V6H4C2.9 6 2 6.9 2 8V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V8C22 6.9 21.1 6 20 6ZM10 4H14V6H10V4ZM20 20H4V8H20V20Z"
          fill="white"
        />
        <path d="M13 10H11V13H8V15H11V18H13V15H16V13H13V10Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_971_11572">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </span>
);

export const StoryblokMedicalVisitCard: StoryblokReactComponent<MedicalVisitCardStoryblok> = ({ component, additionalData }) => {
  if (!component.link || !component.title) return null;

  return (
    <Link href={component.link.url.replace('[[city]]', additionalData.location ?? '')}>
      <li className="gap-15 flex w-full items-center justify-between">
        <div className="flex items-center gap-3">
          <MediacalIcon />
          <span className="text-start">{component.title.replaceAll('[[city]]', capitalizeWordsInString(additionalData.location))}</span>
        </div>
        <RightArrowIcon />
      </li>
    </Link>
  );
};
