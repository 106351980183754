import { ServiceCardStoryblok } from '@/types/types-storyblok';
import medicalServiceIcon from '@/assets/medical-services-icon.svg';
import chevronRightIcon from '@/assets/service-card-chevron-right-icon.svg';
import Image from 'next/image';
import Link from 'next/link';
import { resolveWebLink } from '@/storyblok/utils/link';
import { StoryblokReactComponent } from '@/app/prenota-visita/_storyblok/componentsMap';

const PinIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" className="text-neutral-lighter" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.657 16.657L13.414 20.9C13.2284 21.0857 13.0081 21.233 12.7656 21.3336C12.523 21.4341 12.2631 21.4859 12.0005 21.4859C11.738 21.4859 11.478 21.4341 11.2354 21.3336C10.9929 21.233 10.7726 21.0857 10.587 20.9L6.343 16.657C5.22422 15.5381 4.46234 14.1127 4.15369 12.5608C3.84504 11.009 4.00349 9.40047 4.60901 7.93868C5.21452 6.4769 6.2399 5.22749 7.55548 4.34846C8.87107 3.46943 10.4178 3.00024 12 3.00024C13.5822 3.00024 15.1289 3.46943 16.4445 4.34846C17.7601 5.22749 18.7855 6.4769 19.391 7.93868C19.9965 9.40047 20.155 11.009 19.8463 12.5608C19.5377 14.1127 18.7758 15.5381 17.657 16.657V16.657Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1213 13.1213C14.6839 12.5587 15 11.7956 15 11C15 10.2044 14.6839 9.44129 14.1213 8.87868C13.5587 8.31607 12.7956 8 12 8C11.2044 8 10.4413 8.31607 9.87868 8.87868C9.31607 9.44129 9 10.2044 9 11C9 11.7956 9.31607 12.5587 9.87868 13.1213C10.4413 13.6839 11.2044 14 12 14C12.7956 14 13.5587 13.6839 14.1213 13.1213Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const StoryblokServiceCard: StoryblokReactComponent<ServiceCardStoryblok> = ({ component: card }) => {
  const urlParams = new URLSearchParams();
  if (card.location) {
    urlParams.set('location', card.location.toLowerCase());
  }
  urlParams.set('service', card.service.toLowerCase());

  return (
    <li key={card._uid} className="group w-full flex-grow">
      <Link
        href={card.link ? resolveWebLink(card.link) : `/cerca?${urlParams.toString()}`}
        className="shadow-1 flex flex-row items-center rounded-xl border border-white bg-white px-5 py-3 transition-colors group-hover:border-elty-green group-focus:border-elty-green"
      >
        <div className="flex flex-grow flex-col gap-2">
          <div className="flex flex-row items-center gap-3">
            <Image src={medicalServiceIcon} alt="icona servizio medico" aria-hidden />
            <span aria-label="prestazione">{card.service}</span>
          </div>
          {card.location && (
            <div className="text-text-body flex flex-row items-center gap-3 text-sm text-neutral-lighter">
              <PinIcon />
              <span aria-label="posizione">{card.location}</span>
            </div>
          )}
          {card.description && (
            <div className="flex flex-row items-center gap-3 py-3 pr-3 text-sm text-neutral-lighter">
              <span aria-label="posizione">{card.description}</span>
            </div>
          )}
        </div>
        <Image
          className="shrink-0 translate-x-0 transition-transform group-hover:translate-x-2"
          src={chevronRightIcon}
          alt="icona freccia destra"
          aria-hidden
        />
      </Link>
    </li>
  );
};
